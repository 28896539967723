// src/utils/pdfParser.js
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
// import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.min';

pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.mjs'; 

const parseDateTime = (input) => {
    // Remove the prefix 'D:'
    let dateTimeStr = input.slice(2);

    // Extract the year, month, day, hour, minute, second, and timezone
    let year = dateTimeStr.slice(0, 4);
    let month = dateTimeStr.slice(4, 6);
    let day = dateTimeStr.slice(6, 8);
    let hour = dateTimeStr.slice(8, 10);
    let minute = dateTimeStr.slice(10, 12);
    let second = dateTimeStr.slice(12, 14);
    let timezone = dateTimeStr.slice(14); // "-04'00'"

    // Remove the quotes around the timezone
    timezone = timezone.replace(/'/g, "");

    // Combine parts into an ISO 8601 string
    let isoString = `${year}-${month}-${day}T${hour}:${minute}:${second}${timezone}`;

    // Create and return a Date object
    return new Date(isoString);
}

export const extractCommentsFromPDF = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
  // console.log(pdf);

  const comments = [];
  let num = 1;
  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const annotations = await page.getAnnotations();
    // console.log(annotations);
    let commentDict = {};
    annotations.forEach((annotation) => {
      if (annotation.subtype === 'Text' && annotation.contentsObj) {
        // console.log(parseDateTime(annotation.modificationDate))
        let replyTo = num;
        if (annotation.hasOwnProperty('inReplyTo')) {
            replyTo = commentDict[annotation.inReplyTo];
        }
        else {
            commentDict[annotation.id] = num;
        }
        comments.push({
            index: num,
            page: i,
            content: annotation.contentsObj.str,
            author: annotation.titleObj.str,
            modificationDate: parseDateTime(annotation.modificationDate).toLocaleDateString(),
            // modificationDate: parseDateTime(annotation.modificationDate)
            replyto: annotation.hasOwnProperty('inReplyTo') ? replyTo : ''
        });
        num++;
      }
    });
  }

  console.log(comments);

  return comments;
};
