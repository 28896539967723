// src/components/PDFDropzone.js
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const PDFDropzone = ({ onFileDrop }) => {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type === 'application/pdf') {
      onFileDrop(file);
    } else {
      alert('Please upload a PDF file.');
    }
  }, [onFileDrop]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={styles.dropzone}>
      <input {...getInputProps()} />
      <p>Drop a PDF file here, or click to select one.</p>
    </div>
  );
};

const styles = {
  dropzone: {
    border: '2px dashed #ccc',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  },
};

export default PDFDropzone;
